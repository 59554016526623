var mapConfig = {
  defaultZoomLevel: 0,
  defaultLat: 0,
  defaultLong: 0,
  markerZoomLevel: 15,
  desktopZoom:10,
  mobileZoom:11
};

mapConfig.mapProps = {
  center: {
    lat: 0,
    lng: 0
  },
  zoom: 2,
  panControl: true,
  panControlOptions: {
    position: 1
  },
  streetViewControl: true,
  streetViewControlOptions: {
    position: 1
  },
  zoomControl: true,
  zoomControlOptions: {
    style: 1,
    position: 1
  },
  scaleControl: true,
  clickableIcons: true,
  noClear: false,
  draggable: true,
  scrollwheel: true,
  mapTypeControl: false,
  gestureHandling: 'greedy'
};

// {{mapConfig.customControl}} this flag is hard coded now, to switch between new and old map pins.
mapConfig.customControl = true; 

mapConfig.noStreetViewControl = false;

mapConfig.addressPinDimension = {width: 33, height: 33};
mapConfig.blankPinDimension = {width: 32, height: 32};

mapConfig.markerOMSOptions = {
  circleSpiralSwitchover: 0,
  spiralLengthStart: 50,
  spiderfiedShadowColor: null,
  markersWontMove: true,
  markersWontHide: true,
  nearbyDistance: 1,
  minNudgeZoomLevel: 29,
  keepSpiderfied: true,
  nudgeBucketSize: 0,
  maxNudgeCount: false,
  minZoomLevel: 20
};

mapConfig.clusterProps = {
  styles: [{
    textColor: '#FFFFFF',
    height: 70,
    width: 70
  }],
  maxZoom: 19
};

mapConfig.pushPinSpritePoint = {
  'AR': 40,
  'AK': 80,
  'CY': 120,
  'ER': 160,
  'EB': 200,
  'FI': 240,
  'GE': 280,
  'GR': 320,
  'JW': 360,
  'MC': 400,
  'ET': 400,
  'OX': 440,
  'BR': 480,
  'RZ': 520,
  'RI': 560,
  'SH': 600,
  'TP': 640,
  'MV': 680,
  'GREY': 720,
  'PR': 760,
  'DE': 800
};

mapConfig.pushPinIcon = {
  width: 25,
  height: 40,
  count: 21,
  scaleSize: 1.4
};


mapConfig.markerProps = {
  icon: {
    scaledSize: {width:88, height: 32},
    origin: { x:0 , y:0},
    labelOrigin: {
      x: 42,
      y: 12
    }
  },
  hotelIcon: {
    scaledSize: {width: 200, height: 32},
    origin: { x:0 , y:0},
    labelOrigin: {
      x: 95,
      y: 12
    }
  },
  airportIcon: {
    labelOrigin: {
      x: 22,
      y: 32
    }
  },
  color: '#FFF',
  radius:'50000',
  hotelLength: 25,
  hotelLengthKr: 15,
  hotelLengthJp: 12,
  hotelLengthRu: 21,
  hotelFont: '0.875rem',
  rateFont: '1rem',
  hotelFontWeight: '600',
  hotelLineHeight:'0.875rem'
};

mapConfig.styledMapType = [
  {
    "featureType":"all",
    "elementType":"all",
    "stylers":[
      {
        "visibility":"simplified"
      }
    ]
  },
  {
    "featureType":"administrative",
    "elementType":"labels.text",
    "stylers":[
      {
        "color":"#333333"
      }
    ]
  },
  {
    "featureType":"poi.business",
    "elementType":"icon",
    "stylers":[
      {
        "visibility":"off"
      }
    ]
  },
  {
    "featureType":"poi",
    "elementType":"labels.text.fill",
    "stylers":[
      {
        "color":"#777777"
      },
      {
        "saturation":"0"
      }
    ]
  },
  {
    "featureType":"poi.park",
    "elementType":"all",
    "stylers":[
      {
        "visibility":"simplified"
      }
    ]
  },
  {
    "featureType":"poi.park",
    "elementType":"geometry.fill",
    "stylers":[
      {
        "lightness":"33"
      },
      {
        "color":"#e3f4cf"
      },
      {
        "saturation":"0"
      }
    ]
  },
  {
    "featureType":"road",
    "elementType":"labels.text",
    "stylers":[
      {
        "visibility":"on"
      }
    ]
  },
  {
    "featureType":"road",
    "elementType":"labels.text.fill",
    "stylers":[
      {
        "color":"#777777"
      },
      {
        "saturation":"0"
      }
    ]
  },
  {
    "featureType":"water",
    "elementType":"all",
    "stylers":[
      {
        "saturation":"0"
      }
    ]
  },
  {
    "featureType":"water",
    "elementType":"geometry.fill",
    "stylers":[
      {
        "saturation":"0"
      },
      {
        "color":"#cce1f2"
      }
    ]
  }
];

mapConfig.reslinkPropertyLocation = {
  mapLocaleConfig: {
    "default": {
      "maxCharacterLimit": 23
    },
    "ja_JP": {
      "maxCharacterLimit": 11
    },
    "ar_AE": {
      "maxCharacterLimit": 32
    },
    "zh_CN": {
      "maxCharacterLimit": 13
    }
  },
  propertyMarker: {
    label: {
      color: '#ffffff',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'proxima-nova,Helvetica,Arial,sans-serif'
    },
    icon: {
      scaledSize: {width: 200, height: 32},
      labelOrigin: {
        x: 100,
        y: 12
      }
    },
    iconArabic: {
      scaledSize: {width: 200, height: 32},
      labelOrigin: {
        x: 14,
        y: 12
      }
    }
  },
  propertyMap:{
    zoom: 10,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    clickableIcons: false
  },
  airportMarker: {
    label: {
      color: '#ffffff',
      fontSize: '12px',
      fontWeight: '600',
      fontFamily: 'proxima-nova,Helvetica,Arial,sans-serif'
    },
    icon: {
      scaledSize: {width:48, height:48},
      labelOrigin: {
        x: 23,
        y: 33
      }
    },
    iconArabic: {
      scaledSize: {width:48, height:48},
      labelOrigin: {
        x: 12,
        y: 33
      }
    }
  },
  airportMarkerMobile: {
    icon: {
      scaledSize: {width:30, height:30}
    }
  },
  airportFilter: {
    radius: '50000'
  },
  ellipsis: '\u2026'
};

module.exports = mapConfig;