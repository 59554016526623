var $ = require('jquery');
var _ = require('lodash');
var ComponentMapper = require('./component-mapper');
var MapConfig = require('./googleMapConfig');
var jsGoogleApiWrapper = require('js-google-api-wrapper');

var properties = {
  /**
	* This function is used to initiate google api loading and rendering.
	* @return void
	**/
  init: function() {
    var _self = this;
    this.constructURL();
    this.initGoogleApi(_self.createGoogleApiObjects, _self.fallback);
  },
  constructURL: function() {
    if(this.language) {
      this.apiUrl += '&language='+this.language;
    }
  },
  fallback: $.noop,
  /**
	* This function loads google maps API.   
	* @param callback - called if google maps API is loaded successfully
	* @param fallback - called if google maps API fails to load
	* @return null
	**/
  initGoogleApi: function(callback, fallback) {
    var _self = this;
    if (_self.apiUrl) {
      jsGoogleApiWrapper.loadApi(_self.apiUrl)
        .then(function(apiWrapper) {
          callback.call(_self);
          if(typeof _self.success === 'function') {
            _self.success(apiWrapper)
          }
        }, function(err) {
          if (typeof fallback === 'function') {
            fallback.call(_self);
          }
          if(typeof _self.error === 'function') {
            _self.error()
          }
        });
    }
  },
  /**
	* This function creates a geocoder object that is used for address search and googlePlaces oject that is used for places search.
	* @return void
	**/
  createGoogleApiObjects: function() {
    var _self = this;

    if (!window.geocoder) {
      window.geocoder = new google.maps.Geocoder;
    }
    if (!window.googleMaps) {
      window.googleMaps = new google.maps.Map(document.createElement('div'),MapConfig.mapProps);
    }
    _.defer(function() {
      _self.pubsub.publish('GOOGLE_API_LOADED');
    });
  }
};

module.exports = {
  create: function(props) {
    var obj = _.cloneDeep(properties);
    var GoogleApiInstance = ComponentMapper.extend(obj);
    return new GoogleApiInstance(props);
  }
};
