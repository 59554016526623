/* Component Dependencies */
var reslinkPropertyLocationTemplate = require('templates/reslinkPropertyLocation.hbs');
var AriesComponent = require('libs/aries-component');
var googleApiWrapper = require('js-google-api-wrapper');
var MapConfig = require('libs/googleMapConfig');
var GoogleApi = require('libs/google-api');
var MarkerClustererPlus = require('marker-clusterer-plus');
var baiduCluster = require('libs/baidumaps/map-cluster');
var ResponsiveUtils = require('utils/responsiveUtils');
var mapBoundMarkerArray = [];

AriesComponent.create({
  type: 'reslinkPropertyLocation',
  template: {
    'reslinkPropertyLocation': reslinkPropertyLocationTemplate
  },

  bindEvents: function () {
    var _self = this,
      originalWidth = window.innerWidth;
    _self.tileData = _self.$el.find('.data-tile');
    _self.markers = [];
    this.markerData= [];
    this.pointArray = [];
    this.bmap;
    if(_self.$el.find('.js-baidu-map').length) {
      _self.initBaidu();
    } else {
      _self.loadMapsApi();
    }
    _self.initResponsiveUtils();
    $(window).resize(function(){
      var newWidth = window.innerWidth;
      if(newWidth !== originalWidth && !_self.$el.find('.js-baidu-map').length){
        _self.initHotelMap();
        originalWidth = newWidth;
      }
    });
  },
  initBaidu: function _initBaidu() {
    var _self = this;
    this.bmap = new BMap.Map("baidumap");
    var offset = {
      type: BMAP_NAVIGATION_CONTROL_SMALL,
      anchor: BMAP_ANCHOR_TOP_LEFT
    };
    var propertyList = _self.$el.find(".t-property-data");
    for (i = 0; i < propertyList.length; i++) {
      this.pointArray.push(new BMap.Point(propertyList.eq(i).data('longitude'), propertyList.eq(i).data('latitude')));
    }
    var mapCenter = this.bmap.getViewport(this.pointArray);
    this.bmap.centerAndZoom(mapCenter.center, mapCenter.zoom);
    this.bmap.addControl(new BMap.NavigationControl(offset));
    this.bmap.enableScrollWheelZoom();
    _self.createBaiduMarker();
    this.bmap.addEventListener('zoomend',function(){
         if(_self.markerCluster)
          _self.markerCluster.clearMarkers();
         _self.createBaiduMarker();
    });
  this.bmap.addEventListener('moveend',function(){
    if(_self.markerCluster)
      _self.markerCluster.clearMarkers();
    _self.createBaiduMarker();
  });
  },
  createBaiduMarker: function _createBaiduMarker() {
    var _self = this;
    this.markerData = [];
    var propertyList = _self.$el.find(".t-property-data");
    for (i = 0; i < propertyList.length; i++) {
      this.markerData.push(
        {
          lat: propertyList.eq(i).data('latitude'),
          long: propertyList.eq(i).data('longitude'),
          hotelName: propertyList.eq(i).data('property')
        });
    }
    _self.createBaiduLabel(this.markerData);
  },
  createBaiduLabel: function _createBaiduLabel(markerData) {
    var baiduMarker;
    var iconBaidu = new BMap.Symbol(BMap_Symbol_SHAPE_CIRCLE, {
      scale: 0.1,
      strokeWeight: 0.1,
      strokeOpacity: 0,
      fillColor: '#494949',
      fillOpacity: 0
    });
    this.bmap.clearOverlays();
    this.markers = [];
    for (var i = 0; i < markerData.length; i++) {
      if(!markerData[i].isDeleted) {
      baiduMarker = new BMap.Marker(new BMap.Point(markerData[i].long, markerData[i].lat),{
        icon: iconBaidu
      });
      this.label = new BMap.Label('<div class="map-marker map-marker-default blank"><div class="map-rate-details">' + markerData[i].hotelName + '</div><div class="arrow"></div></div>');
      this.label.setPosition(new BMap.Point(markerData[i].long, markerData[i].lat));
      this.label.setStyle({
        background: 'none',
        color: '#fff',
        contentAlign:'center',
        border: 'none'
      });
      baiduMarker.customData=markerData[i];
      baiduMarker.customData.indexBaidu=i;
      this.label.customData= baiduMarker.customData;
      this.label.customData.marker=baiduMarker;
      baiduMarker.setLabel(this.label);
      this.markers.push(baiduMarker);
      }
    }
      this.createBaiduCluster(this.markers);
  },
  createBaiduCluster: function _createBaiduCluster(markerArray) {
    var clusterUrl = this.$el.find('.l-map-container').data('img').pinCluster;
    this.markerCluster = new baiduCluster.BMapLib.MarkerClusterer(this.bmap,
      {
        markers: markerArray,
        styles: [{
          url: clusterUrl,
          size: new BMap.Size(72, 72),
          opt_anchor: [32, 0],
          textColor: 'white',
          opt_textSize: 14
        }],
        openInfoWindow: false
      });
  },
  /**
   * Load Google API and initialize Map on success callback
   * @return {void}
   */
  loadMapsApi: function () {
    var _self = this;
    googleApiWrapper.loadApi(_self.tileData.data('googleMapApiUrl')).then(function () {
      _self.initHotelMap();
    });
  },

  /**
   * Initialize marker with brand icon
   * @return {Object} google.maps.Marker object with brand icon
   */
  createMarkers: function (map) {
    var _self = this,
      locations = _self.tileData.data('locations') || [],
      imageIcon = _self.tileData.data('img').pinGoogleMap,
      clusterIcon = _self.tileData.data('img').pinCluster,
      myLocale = _self.context.context.localeKey,
      isRTL = _self.context.context.isRTL,
      propertyMarkerIcon,
      omsList = new this.oms(map, MapConfig.markerOMSOptions),
      markers,
      listener;

      propertyMarkerIcon = MapConfig.reslinkPropertyLocation.propertyMarker.icon;
    
      markers = locations.map( function(location) {
        var name, mapMarker, showLocalizedName, limit;

        showLocalizedName = location.showLocalizedName;

        limit = showLocalizedName.toString() === 'true' ? (MapConfig.reslinkPropertyLocation.mapLocaleConfig[myLocale] ? MapConfig.reslinkPropertyLocation.mapLocaleConfig[myLocale].maxCharacterLimit : MapConfig.reslinkPropertyLocation.mapLocaleConfig['default'].maxCharacterLimit) : MapConfig.reslinkPropertyLocation.mapLocaleConfig['default'].maxCharacterLimit;

        name = location.propertyName.length > limit ? location.propertyName.substring(0,limit) + MapConfig.reslinkPropertyLocation.ellipsis : location.propertyName;

        mapMarker = new google.maps.Marker( {
          position: { lat: location.latitude, lng: location.longitude },
          label: $.extend( {
            text: name
          }, MapConfig.reslinkPropertyLocation.propertyMarker.label),
          icon: $.extend( {
            url: imageIcon
          }, propertyMarkerIcon )
        } );
        
        // Fix for overlapping markers
        omsList.addMarker(mapMarker);
        mapBoundMarkerArray.push(mapMarker);

      return mapMarker;
    } );

    // Cluster Icon
    MapConfig.clusterProps.styles[0].url = clusterIcon;
    markerCluster = new MarkerClusterer(map, markers, MapConfig.clusterProps);
    _self.mapBounds(mapBoundMarkerArray);
  },

  // Airport Markers
  renderAirportMarkers: function() {
    var _self = this,
      airportCodes,
      hotelDetails =[],
      i,
      j,
      request={},
      airportLen,
      requestedHotels = _self.$el.find('.t-property-data'),
      hotelLen = requestedHotels.length,
      service = new google.maps.places.PlacesService(_self.hotelMap);

    for(i=0;i<hotelLen;i++) {
      hotelDetails.push(
        {
          lat: requestedHotels.eq(i).data('latitude'),
          long: requestedHotels.eq(i).data('longitude'),
          airportCodes: requestedHotels.eq(i).data('airport-codes')
        }
      );
    } 

    for(i=0;i<hotelLen;i++) {
      airportLen = hotelDetails[i].airportCodes.length;
      for(j=0;j<airportLen;j++) {
        request = {};
        request.location = {lat: hotelDetails[i].lat, lng: hotelDetails[i].long};
        request.radius = MapConfig.reslinkPropertyLocation.airportFilter.radius;
        request.type = ['airport'];
        request.keyword = hotelDetails[i].airportCodes[j];
        _self.filterNearbyAirports(request);
      }
    }
  },
  filterNearbyAirports:function(request) {
    var _self = this,
      service = new google.maps.places.PlacesService(_self.hotelMap);
    service.nearbySearch(request,function(results,status){
      if(status === google.maps.places.PlacesServiceStatus.OK) {
          _self.createAirportMarker(results,request);
      }
    })
  },
  createAirportMarker:function(results,code) {
    var _self = this,
      airportIcon = _self.tileData.data('img').pinAirport,
      airportIconMobile = _self.tileData.data('img').pinAirportMobile,
      place = results[0].geometry.location,
      airportCode = code.keyword,
      isRTL = _self.context.context.isRTL,
      airportMarkerIconConfig = typeof isRTL !== 'undefined' ? (isRTL.toString() === 'true' ? MapConfig.reslinkPropertyLocation.airportMarker.iconArabic : MapConfig.reslinkPropertyLocation.airportMarker.icon) : MapConfig.reslinkPropertyLocation.airportMarker.icon,
      marker;
      
    if (_self.responsiveUtils.isMobile()) {
      marker = new google.maps.Marker({
        position: place,
        map:_self.hotelMap,
        icon: $.extend({
          url: airportIconMobile
        }, MapConfig.reslinkPropertyLocation.airportMarkerMobile.icon)
      });
    }
    else{
      marker = new google.maps.Marker({
        position: place,
        map:_self.hotelMap,
        label: $.extend({
          text: airportCode
        }, MapConfig.reslinkPropertyLocation.airportMarker.label),
        icon: $.extend({
          url: airportIcon
        }, airportMarkerIconConfig)
      });
    }
    
    marker.setZIndex(google.maps.Marker.MAX_ZINDEX * -1);
    mapBoundMarkerArray.push(marker);
    _self.mapBounds(mapBoundMarkerArray);
  },
  
  // Fix for showing all markers
  mapBounds: function(marker){
    var _self = this,
      bounds = new google.maps.LatLngBounds();

    for (var i = 0; i < marker.length; i++) {
      bounds.extend(marker[i].getPosition());
    }

    _self.hotelMap.setCenter(bounds.getCenter());
    _self.hotelMap.fitBounds(bounds);
			setTimeout(function(){	
				if(_self.hotelMap.getZoom() > 17){	
					_self.hotelMap.setZoom(17);	
				}	
			},1000);
  },

  /**
  * This function is used to initiate responsive utils
  */
  initResponsiveUtils: function() {
    this.responsiveUtils = ResponsiveUtils.create({
    });
    this.responsiveUtils.init();
  },

  /**
   * Initialize Map with Hotel & Airport markers
   * @return {void}
   */
  initHotelMap: function () {
    var _self = this, map;
    map = _self.getMap(_self.tileData.get(0));
    _self.createMarkers(map);
  },
  
  /**
   * Initialise map with hotel latitude and longitude
   * @param {HTMLElement} mapContainerElm - Map container DOM element
   * @return {Object} google.maps.Map object
   */
  getMap: function (mapContainerElm) {
    var _self = this;
    _self.oms = require('overlapping-marker-spiderfier');
    _self.hotelMap = new google.maps.Map(mapContainerElm, MapConfig.reslinkPropertyLocation.propertyMap);
    return _self.hotelMap;
  }
});
